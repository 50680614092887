import {
    CLEAR_ERRORS,
    CLEAR_STATE,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_PROFILE_REQUEST,
    USER_PROFILE_SUCCESS,
    USER_PROFILE_FAIL,
    CLEAR_PROFILE_ERRORS,
    USER_LOGOUT_REQUEST,
    USER_LOGOUT_SUCCESS,
    CLEAR_USER_LOADING,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
  } from "../constants/userConstant.js";
  
  // USER LOGIN REDUCER
  export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_LOGIN_REQUEST:
        return {
          loading: true,
          isAuthenticated: false,
          userInfo: {},
        };
  
      case USER_LOGIN_SUCCESS:
        return {
          loading: false,
          isAuthenticated: true,
          userInfo: action.payload,
        };
  
      case USER_LOGIN_FAIL:
        return {
          loading: false,
          isAuthenticated: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          loading: false,
          isAuthenticated: false,
          userInfo: null,
          error: null,
        };
      case CLEAR_STATE:
        return {
          loading: false,
          isAuthenticated: false,
          error: null,
          userInfo: {},
        };
      case CLEAR_USER_LOADING:
        return {
          ...state,
          loading: false,
        };
  
      case USER_LOGOUT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case USER_LOGOUT_SUCCESS:
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          userInfo: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET USER PROFILE REDUCER
  export const userProfileReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_PROFILE_REQUEST:
        return {
          loading: true,
          isAuthenticated: false,
          userInfo: {},
        };
      case USER_PROFILE_SUCCESS:
        return {
          loading: false,
          isAuthenticated: true,
          userInfo: action.payload,
        };
      case USER_PROFILE_FAIL:
        return {
          loading: false,
          isAuthenticated: false,
          error: action.payload,
        };
      case CLEAR_PROFILE_ERRORS:
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          error: null,
        };
  
      case USER_LOGOUT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case USER_LOGOUT_SUCCESS:
        return {
          ...state,
          loading: false,
          isAuthenticated: false,
          userInfo: {},
        };
  
      default:
        return state;
    }
  };
  
  // FORGOT PASSWORD REDUCER
  export const forgotPasswordReducer = (state = {}, action) => {
    switch (action.type) {
      case FORGOT_PASSWORD_REQUEST:
        return {
          loading: true,
          data: {},
        };
      case FORGOT_PASSWORD_SUCCESS:
        return {
          loading: false,
          data: action.payload,
        };
      case FORGOT_PASSWORD_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // RESET PASSWORD REDUCER
  export const resetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
      case RESET_PASSWORD_REQUEST:
        return {
          loading: true,
          data: {},
        };
      case RESET_PASSWORD_SUCCESS:
        return {
          loading: false,
          data: action.payload,
        };
      case RESET_PASSWORD_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
