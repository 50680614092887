import {
  ALL_COMPANIES_FAIL,
  ALL_COMPANIES_REQUEST,
  ALL_COMPANIES_SUCCESS,
  CLEAR_COMPANY_ERROR,
} from "../constants/companyConstant";

// GET ALL COMPANIES REDUCER
export const getAllCompaniesReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_COMPANIES_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case ALL_COMPANIES_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case ALL_COMPANIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
