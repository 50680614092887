import React from "react";
import "../styles/dashboard.scss";
import DashboardLayout from "../components/layouts/DashboardLayout";
import WelcomeMessage from "../components/WelcomeMessage";
import PageTitle from "../components/PageTitle";
import { CircleCard } from "../components/styles";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ArcElement,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import { RiTeamFill } from "react-icons/ri";
import { MdPolicy } from "react-icons/md";

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip);

const Dashboard = () => {
  const navigate = useNavigate();

  const { myPolicies, agentPolicyLoading, allCompanies, auth } = useAuth();
  const user =auth?.user
  const policiesByCompany = allCompanies?.map((company) => {
    const policies = myPolicies?.filter(
      (policy) => policy?.insurerCompany?._id === company?._id
    );
    return {
      companyName: company.companyName,
      policies: policies,
    };
  });

  let pointsData;
  if (myPolicies?.length) {
    const aggregatedPolicies =
      myPolicies &&
      myPolicies?.reduce((acc, policy) => {
        const referrerId = policy?.policyRefferer?._id;
        if (!acc[referrerId]) {
          acc[referrerId] = {
            ...policy.policyRefferer,
            totalCommission: 0,
          };
        }
        acc[referrerId].totalCommission += policy.policyReffererCommission;
        return acc;
      }, {});

    const result = Object.values(aggregatedPolicies);
    if (result?.length) {
      pointsData = result;
    }
  }

  const dashTopData = [
    {
      mainData: agentPolicyLoading ? 0 : myPolicies?.length,
      title: "Your Policies",
      desc: "Your created policies till now...",
      buttonTxt: "See More",
      buttonAction: "/policies",
    },
    {
      mainData: <RiTeamFill />,
      title: "Associates",
      desc: "Your Team...",
      buttonTxt: "See More",
      buttonAction: `/my-team/${user?._id}`,
    },
    {
      mainData: pointsData?.[0]?.totalCommission
        ? pointsData?.[0]?.totalCommission
        : 0,
      title: "Points",
      desc: "Your points which you earned...",
      buttonTxt: "See More",
      buttonAction: "/policies",
    },
    {
      mainData: <MdPolicy />,
      title: "Team Policies",
      desc: "Policies which created by you direct team...",
      buttonTxt: "See More",
      buttonAction: "/team-policies",
    },
  ];

  // GET RANDOM LIGHT COLOR
  function getRandomLightColors() {
    const colorArr = [];

    for (let index = 0; index < 10; index++) {
      const r = Math.floor(200 + Math.random() * 55); // Between 200 and 255
      const g = Math.floor(200 + Math.random() * 55); // Between 200 and 255
      const b = Math.floor(200 + Math.random() * 55); // Between 200 and 255
      colorArr.push(`rgb(${r}, ${g}, ${b})`);
    }

    return colorArr;
  }
  const colorArr = getRandomLightColors();

  // GET RANDOM SOLID COLOR
  function getRandomSolidColors() {
    const colorArr = [];

    for (let index = 0; index < 5; index++) {
      const r = Math.floor(50 + Math.random() * 255); // Between 1 and 1
      const g = Math.floor(50 + Math.random() * 255); // Between 1 and 1
      const b = Math.floor(50 + Math.random() * 255); // Between 1 and 1
      colorArr.push(`rgb(${r}, ${g}, ${b})`);
    }

    return colorArr;
  }
  const solidColor = getRandomSolidColors();

  // DOUGHNUT STATE DATA
  const doughnutState = {
    labels: policiesByCompany?.map((item) => item?.companyName),
    datasets: [
      {
        backgroundColor: policiesByCompany?.map(
          (_, index) => solidColor[index]
        ),
        hoverBackgroundColor: policiesByCompany?.map(
          (_, index) => colorArr[index]
        ),
        data: policiesByCompany?.map((item) => item?.policies?.length),
      },
    ],
  };

  return (
    <div className="dashboard_main">
      <DashboardLayout>
        <div className="dashboard_content">
          <PageTitle title="Dashboard" />
          <div className="container">
            <div className="dashTopData">
              {dashTopData?.map((item, index) => (
                <CircleCard
                  key={index}
                  className="dataCard"
                  style={{ background: "lightgrey" }}
                >
                  <div className="mainData">{item?.mainData}</div>
                  <div className="title">{item?.title}</div>
                  <div className="desc">{item?.desc}</div>
                  <button
                    className="button"
                    onClick={() => navigate(item?.buttonAction)}
                  >
                    {item?.buttonTxt}
                  </button>
                </CircleCard>
              ))}
            </div>

            <div className="chartContent">
              <div className="chart" style={{ background: "lightgrey" }}>
                <div className="cardHeading">Policies By Company</div>
                {!policiesByCompany?.length ? (
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      textAlign: "center",
                      color: "rgb(52, 71, 103)",
                    }}
                  >
                    Please create some policies afterthat you will see your
                    performance panel
                  </p>
                ) : (
                  <Doughnut data={doughnutState} />
                )}

                {/* <div className="policyData">
                  {allCompanies?.map((item, itemIndex) => (
                    <div className="nameColor" key={itemIndex}>
                      <span style={{ background: solidColor[itemIndex] }} />{" "}
                      <span>{item?.companyName}</span>
                    </div>
                  ))}
                </div> */}
              </div>
              <div className="impMsg" style={{ background: "lightgrey" }}>
                <div className="cardHeading">Important Message</div>

                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    textAlign: "center",
                    color: "rgb(52, 71, 103)",
                  }}
                >
                  There are no messages for you right now
                </p>

                {/* <ul>
                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur. sit amet consectetur.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur. sit amet consectetur.
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor
                      sit amet consectetur. sit amet consectetur.
                    </li>
                  </ul> */}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default Dashboard;
