import React, { useEffect, useState } from "react";
import "../styles/policies.scss";
import DashboardLayout from "../components/layouts/DashboardLayout";
import PageTitle from "../components/PageTitle";
import moment from "moment";
import usePaginate from "../hooks/usePaginate";
import ReactPaginate from "react-paginate";
import { RxDoubleArrowRight, RxDoubleArrowLeft } from "react-icons/rx";
import { useAuth } from "../context/userContext";
import Loader from "../components/Loader";
import CustomInput from "../components/CustomInput";
import NoData from "../components/NoData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Policies = () => {
  const [searchString, setSearchString] = useState("");
  const { agentPolicyLoading, myPolicies } = useAuth();
  const [policyData, setPolicyData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [selectedType, setSelectedType] = useState("searchString");

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0);

      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);

      const filtered = myPolicies.filter((item) => {
        console.log("item", item);

        const createdAt = new Date(item.createdAt);
        return createdAt >= start && createdAt <= end;
      });

      setPolicyData(filtered);
    } else if (searchString) {
      setPolicyData(
        myPolicies?.filter((policy) =>
          policy?.policyHolder
            ?.toLocaleLowerCase()
            .includes(searchString?.toLocaleLowerCase())
        )
      );
    } else {
      setPolicyData(myPolicies);
    }
  }, [myPolicies, searchString, endDate, startDate]);

  // Pagination Hook Start
  const { currentItems, pageCount, handlePageClick } = usePaginate(policyData);
  // Pagination Hook Ends

  useEffect(() => {
    if (selectedType === "searchString") {
      setDateRange([null, null]);
    } else {
      setSearchString("");
    }
  }, [selectedType]);

  return agentPolicyLoading ? (
    <Loader />
  ) : (
    <div className="custombersMain agentPoliciesMain">
      <DashboardLayout>
        <PageTitle title="Policies & Points" />
        <div
          className="customerData"
          style={{ marginTop: "40px", marginBottom: "40px" }}
        >
          <div className="container">
            {/* <Table columns={columns} data={data} handleAction={handleAction} /> */}
            <div className="agents_table mt-4">
              {!myPolicies?.length ? (
                <NoData content="You have not created policies by you" />
              ) : (
                <>
                  <div className="label_container">
                    <label htmlFor="searchString">
                      <input
                        type="radio"
                        name="choose_label"
                        id="searchString"
                        value="searchString"
                        checked={selectedType === "searchString" ? true : false}
                        onChange={(e) => setSelectedType(e.target.value)}
                      />
                      Filter By Text
                    </label>
                    <label htmlFor="dateRange">
                      <input
                        type="radio"
                        name="choose_label"
                        id="dateRange"
                        value="dateRange"
                        checked={selectedType === "dateRange" ? true : false}
                        onChange={(e) => setSelectedType(e.target.value)}
                      />
                      Filter By Date Range
                    </label>
                  </div>
                  {selectedType === "searchString" ? (
                    <CustomInput
                      icon=""
                      label="Search policy by policy holder name"
                      type="search"
                      onChange={(e) => setSearchString(e.target.value)}
                      placeholder="Search policy by policy holder name"
                      name="searchString"
                      value={searchString}
                    />
                  ) : (
                    <>
                      <label className="datePickerLabel">
                        Filter policies by Date Range
                      </label>
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setDateRange(update);
                        }}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        withPortal
                        placeholderText="Select a Date Range"
                      />
                    </>
                  )}

                  {searchString && !policyData?.length ? (
                    <NoData
                      content={`Policy not created by the name ${searchString}`}
                    />
                  ) : startDate && endDate && !policyData?.length ? (
                    <NoData
                      content={`Policy not created from ${moment(
                        startDate
                      ).format("DD/MM/YYYY")} to ${moment(endDate).format("DD/MM/YYYY")}`}
                    />
                  ) : (
                    <div className="table-responsive">
                      <table className="table  table-hover table-bordered customTable">
                        <thead>
                          <tr>
                            <th scope="col">Policy No</th>
                            <th scope="col">Policy Holder</th>
                            <th scope="col">Insurer (Company)</th>
                            <th scope="col">Valid Till (Date)</th>
                            <th scope="col">Premium</th>
                            <th scope="col">Associate's Points</th>
                            <th scope="col">Created At (Date)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems?.map((itm, itmIndex) => (
                            <tr key={itmIndex}>
                              <td>
                                {itm?.policyNumber ? itm?.policyNumber : "null"}
                              </td>
                              <td>{itm?.policyHolder}</td>
                              <td>{itm?.insurerCompany?.companyName}</td>
                              <td>
                                {moment(itm?.policyStartDate).format(
                                  "MMM DD, YYYY"
                                )}{" "}
                                -{" "}
                                {moment(itm?.policyEndDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td>₹ {itm?.premium}</td>
                              <td>{itm?.policyReffererCommission}</td>
                              <td>
                                {moment(itm?.createdAt).format("MMM DD, YYYY")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  {policyData?.length > 10 && (
                    <div className="pagination_sec">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<RxDoubleArrowRight />}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={<RxDoubleArrowLeft />}
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default Policies;
