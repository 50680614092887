import React, { useState } from "react";
import "../styles/dashboardBottomBar.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { FaAngleUp, FaHeadphones, FaHome, FaUser, FaUsers } from "react-icons/fa";
import {useDispatch} from "react-redux"
import { logout } from "../redux/actions/userAction";

const DashboardBottomBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openMore, setOpenMore] = useState(false);

  const logouthandler = () => {
    dispatch(logout())
    setOpenMore(false)
    navigate("/")
  }

  return (
    <>
    <div className="dashboardBottomBar_main">
      <div className="wrapper">
        <div className="mainLinks">
        <NavLink to="/dashboard"><FaHome />Dashboard</NavLink>
        <NavLink to="/my-account"><FaUser />Account</NavLink>
        <NavLink to="/policies"><FaUsers />Policies</NavLink>
        <NavLink to="/help"><FaHeadphones />Help</NavLink>
        <div className="action" onClick={()=> setOpenMore(!openMore)}><FaAngleUp />More</div>
        </div>
      </div>
    </div>
    
      <div className={`dashboardBottomBar_moreLinks ${openMore ? "fade-in" : "fade-out"}`}>
        {
          openMore && <div className=""  onClick={()=> logouthandler()}>Log Out</div>
        }
      </div>
    </>
  );
};

export default DashboardBottomBar;
