import {
  CLEAR_STATE,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_SUCCESS,
  USER_PROFILE_FAIL,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_LOGOUT_REQUEST,
  CLEAR_ERRORS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from "../constants/userConstant";
import { axiosInstance } from "../../utils/axiosConfig.js";

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const login =
  (email, panNumber, password) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const config = {
        headers: {
          "content-type": "application/json",
        },
      };

      const { data } = await axiosInstance.post(
        `/login`,
        {
          email,
          panNumber,
          password,
        },
        config
      );

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      sessionStorage.setItem(
        "jsrClient",
        JSON.stringify(getState().userLogin?.userInfo)
      );
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGOUT_REQUEST,
    });

    sessionStorage.removeItem("jsrClient");

    dispatch({ type: CLEAR_STATE });

    dispatch({
      type: USER_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const userProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_PROFILE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrClient")
      ? JSON.parse(sessionStorage.getItem("jsrClient"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// FORGOT PASSWORD - ACTION
export const forgotUserPassword = (email, panNumber) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(`/forgot-password`,{email, panNumber}, config);

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// RESET PASSWORD - ACTION
export const resetUserPassword = (token, password) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(
      `/reset-password`,
      { token, password },
      config
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR STATE ACTION FUNCTION
export const clearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};
