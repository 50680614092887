import { axiosInstance } from "../../utils/axiosConfig.js";
import {
  ALL_COMPANIES_FAIL,
  ALL_COMPANIES_REQUEST,
  ALL_COMPANIES_SUCCESS,
  CLEAR_COMPANY_ERROR,
} from "../constants/companyConstant.js";

// GET ALL COMPANIES
export const getAllCompanies = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_COMPANIES_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrClient")
      ? JSON.parse(sessionStorage.getItem("jsrClient"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/get-companies`, config);

    dispatch({
      type: ALL_COMPANIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_COMPANIES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearCompanyErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_COMPANY_ERROR });
};
