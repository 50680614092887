import React from "react";
import { styled } from "styled-components";

const Heading = styled.h2`
  font-size: 28px;
  margin: 0 0 20px 0;
  font-weight: 500;

  @media screen and (max-width: 767px) {
    font-size: 22px;
  }

  > .bottom_line {
    display: flex;
  }
`;

const SectionHeading = ({ heading, center, underLine }) => {
  const centerStyle = {
    marginRight: "auto",
    marginLeft: "auto",
    width: "fit-content",
  };
  return (
    <Heading className="secTitle" style={center ? centerStyle : {}}>
      {heading}
      {underLine && (
        <div className="bottom_line">
          <svg
            width="125"
            height="8"
            viewBox="0 0 125 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.0514 7.95751C21.6905 8.00573 18.7135 8.03126 15.7594 7.81569C11.5654 7.50638 7.38732 7.05411 3.34541 6.05995C2.35307 5.8146 1.3448 5.51252 0.488662 5.00182C-0.00131788 4.70981 -0.282575 4.43454 0.432056 4.00921C1.20683 3.54546 1.84716 2.94556 2.55472 2.40522C2.98421 2.03294 3.62896 1.87282 4.24577 1.98543C6.34721 2.28892 8.36905 2.85905 10.5041 3.06327C13.4298 3.34691 16.3591 3.56815 19.3025 3.63055C25.3822 3.76103 31.4424 3.48873 37.4991 3.11007C40.8723 2.90017 44.2509 2.74133 47.6242 2.54278C50.7922 2.36267 53.9568 2.16682 57.1231 1.98401C60.7645 1.77213 64.4061 1.56464 68.0477 1.36141C70.7347 1.21108 73.418 1.04657 76.1068 0.923046C79.8621 0.751442 83.6192 0.611181 87.3763 0.460851C89.644 0.370085 91.9116 0.266556 94.1759 0.186994C98.1064 0.0451732 102.051 0.168699 105.983 0.0451732C108.573 -0.0298504 111.154 -6.77946e-05 113.74 0.0524061C116.773 0.117644 119.786 0.351648 122.676 1.15577C123.373 1.35007 123.952 1.7571 124.724 1.85623C125.07 1.90033 125.059 2.23078 124.866 2.45897C124.577 2.8199 124.26 3.16566 123.916 3.4944C123.739 3.66033 123.541 3.87151 123.168 3.83605C121.472 3.6731 119.851 4.2218 118.144 4.1438C116.51 4.06878 114.863 4.18224 113.22 4.20635C110.4 4.24606 107.577 4.24322 104.759 4.32817C101.854 4.41482 98.9502 4.52246 96.0492 4.59338C92.5379 4.67861 89.0355 4.84312 85.5294 4.96792C81.2967 5.10975 77.069 5.36361 72.8396 5.57903C69.1639 5.76638 65.4846 5.92224 61.8159 6.17752C59.4332 6.34203 57.0399 6.43435 54.6661 6.61163C51.1442 6.87258 47.5905 6.90236 44.0758 7.18601C39.4112 7.56183 34.7237 7.6014 30.0574 7.93752C28.269 8.0653 26.4559 7.95751 25.0514 7.95751Z"
              fill="#42275a"
            ></path>
          </svg>
        </div>
      )}
    </Heading>
  );
};

export default SectionHeading;
