import React, { useState } from "react";
import "../styles/help.scss";
import PageTitle from "../components/PageTitle";
import DashboardLayout from "../components/layouts/DashboardLayout";
import { CiLocationOn } from "react-icons/ci";
import { BsEnvelope } from "react-icons/bs";
import { Link } from "react-router-dom";
import { FaRegComments } from "react-icons/fa";
import SectionHeading from "../components/SectionHeading";
import { IoIosArrowDown } from "react-icons/io";

const Help = () => {
  const [showFaq, setShowFaq] = useState("");

  const faqData = [
    {
      question: "How can I become an associate of JSR Associates?",
      answer: "Interested individuals can contact us directly for become an associate. We review applications and will get in touch with qualified candidates.",
    },
    {
      question: "What are the benefits of being a JSR Associate?",
      answer: "As an associate, you’ll receive competitive points, ongoing training, and access to our wide range of insurance products.",
    },
    {
      question: "What is the point structure for JSR's Associates?",
      answer: "Our point structure varies by product and performance. You can review the specific details in your associate agreement or by contacting our support team.",
    },
    {
      question: "How can I track my performance and points?",
      answer: "Associates can log in to our dedicated associate portal to track performance metrics, view points statements, and manage their policies.",
    },
  ];

  return (
    <div className="helpMain">
      <DashboardLayout>
        <PageTitle title="Help" />
        <div
          className="helpContent"
          style={{ marginTop: "40px", marginBottom: "40px" }}
        >
          <div className="container">
            <div className="top_section">
              <div className="address_section">
                <div className="icon">
                  <CiLocationOn />
                </div>
                <h3>Our Address</h3>
                Jai siya Ram associates 1st floor, Opposite kaushik hospital,
                Near Tularam Chowk, Mahendergarh, HR, 123029
              </div>
              <div className="info_section">
                <div className="icon">
                  <BsEnvelope />
                </div>
                <h3>Contact Info</h3>
                <p>Open a chat or give us call at</p>
                <div className="link">
                  <Link to={`tel:+91${process.env.REACT_APP_PHONE_NO}`}>
                    {process.env.REACT_APP_PHONE_NO}
                  </Link>
                </div>
              </div>
              <div className="chat_section">
                <div className="icon">
                  <FaRegComments />
                </div>
                <h3>Live Support</h3>
                <p>live service on mail</p>
                <div className="chat_link">
                  <Link to={`mailto:${process.env.REACT_APP_MAIL}`}>
                    {process.env.REACT_APP_MAIL}
                  </Link>
                </div>
              </div>
            </div>

            <div className="faqSection">
              <SectionHeading
                heading="FAQs for Associates"
                underLine={true}
                center={false}
              />
              <div className="faqDropdown">
                {faqData?.map((itm, itemIndex) => {
                  return (
                    <div className="dropdown-item" key={itemIndex}>
                      <div
                        className={`dropHeading ${
                          showFaq === itemIndex ? "active" : ""
                        }`}
                        onClick={() =>
                          setShowFaq(itemIndex === showFaq ? "" : itemIndex)
                        }
                      >
                        <span>{itm?.question}</span>
                        <span className="icon">
                          <IoIosArrowDown />
                        </span>
                      </div>
                      <div
                        className={`dropData ${
                          itemIndex === showFaq ? "showData" : ""
                        }`}
                      >
                        {itm?.answer}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default Help;
