import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Spinner = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    text-align: center;
    color: #fff;

    > span {
      color: red;
    }
  }
  a {
    background-color: red;
    color: #fff;
    padding: 7px 20px;
    margin: 25px auto 0;
    display: block;
    width: fit-content;
    border-radius: 7px;
  }
`;

const RedirectSpinner = ({ path = "/" }) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => --prevCount);
    }, 1000);
    count <= 0 &&
      navigate(`${path}`, {
        state: location.pathname,
      });
    return () => clearInterval(interval);
  }, [count, navigate, location, path]);


  return (
    <Spinner style={{ background: "#111"}}>
      <div className="container">
        <h3>
          you do not have sufficient privileges to access the resource. <br />{" "}
          Redirecting to you in <span>{count}</span> second.
        </h3>
        <Link to="/">Login</Link>
      </div>
    </Spinner>
  );
};

export default RedirectSpinner;
