import React from "react";
import "../styles/myAccount.scss";
import DashboardLayout from "../components/layouts/DashboardLayout";
import PageTitle from "../components/PageTitle";
import { logo } from "../assets/images";
import { FaRegCopy } from "react-icons/fa";
import { FcApproval, FcCancel } from "react-icons/fc";
import CustomInput from "../components/CustomInput";
import { useAuth } from "../context/userContext";
import { toast } from "react-toastify";
import moment from "moment"

const MyAccount = () => {
  const { auth } = useAuth();
  const user = auth?.user;

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Copied!");
    } catch (err) {
      toast.warning("Failed to copy text");
    }
  };

  return (
    <div className="myAccount_main">
      <DashboardLayout>
        <PageTitle title="My Account" />
        <div className="userContent">
          <div className="container">
            <div className="profilePic">
              <div className="imgSec">
                <img src={logo} alt="logo" />
                {/* <div className="editBtn">
                  <button>
                    <CiEdit />
                    Edit
                  </button>
                </div> */}
              </div>
              <div className="impData">
                <div className="fullName">
                  {user?.fullName}{" "}
                  {user?.isActive ? <FcApproval /> : <FcCancel />}
                </div>
                <div className="uniqueID">
                  {user?.panNumber} <FaRegCopy onClick={()=> copyToClipboard(user?.panNumber)} />
                </div>
                <div className="emailID">{user?.designation?.title}</div>
                <div className="emailID">Joining Date: {moment(user?.createdAt).format("MMM DD, YYYY")}</div>
              </div>
            </div>
            <div className="userData">
              <CustomInput
                icon=""
                label="Full Name"
                type="text"
                // onChange=""
                placeholder="Enter Your Full Name"
                name="fullName"
                value={user?.fullName}
                // impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Email ID"
                type="email"
                // onChange=""
                placeholder="Enter Your Email ID"
                name="email"
                value={user?.email}
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Mobile Number"
                type="number"
                // onChange=""
                placeholder="Enter Your Mobile Number"
                name="phone"
                value={user?.phone}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Village"
                type="text"
                // onChange=""
                placeholder="Your Village"
                name="village"
                value={user?.village}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="City"
                type="text"
                // onChange=""
                placeholder="City"
                name="city"
                value={user?.city}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="State"
                type="text"
                // onChange=""
                placeholder="State"
                name="state"
                value={user?.state}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Pin Code"
                type="number"
                // onChange=""
                placeholder="Pin Code"
                name="pinCode"
                value={user?.pinCode}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
              <CustomInput
                icon=""
                label="Status"
                type="text"
                // onChange=""
                placeholder="Profile Status"
                name="profileStatus"
                value={user?.isActive ? "Approved" : "Disabled"}
                impStar="*"
                readOnly={true}
                borderColor="#42275a"
              />
            </div>
            {/* <button>Update</button> */}
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default MyAccount;
