import React, { useEffect, useState } from "react";
import "../styles/resetPassword.scss";
import PageTitle from "../components/PageTitle";
import CustomInput from "../components/CustomInput";
import { clearErrors, forgotUserPassword } from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [selectedType, setSelectedType] = useState("pan");
  const [successMessage, setSuccessMessage] = useState("");

  // GET RANDOM SOLID COLOR
  function getRandomSolidColors() {
    const r = Math.floor(50 + Math.random() * 105); // Between 1 and 1
    const g = Math.floor(50 + Math.random() * 105); // Between 1 and 1
    const b = Math.floor(50 + Math.random() * 105); // Between 1 and 1
    const colorArr = `rgb(${r}, ${g}, ${b})`;

    return colorArr;
  }
  const solidColor = getRandomSolidColors();

  useEffect(() => {
    if (selectedType === "email") {
      setPanNumber("");
    } else {
      setEmail("");
    }
  }, [selectedType]);

  // FORGOT PASSWORD ACTION DATA START
  const { loading, data, error } = useSelector((state) => state.forgotPassword);

  const handleForgotPassword = (e) => {
    e.preventDefault();
    dispatch(forgotUserPassword(email, panNumber));
  };

  useEffect(() => {
    if (data?.success) {
      toast.success(data?.message);
      setSuccessMessage(data?.message);
      setEmail("");
      setPanNumber("");
    }
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error, data?.message, data?.success]);

  // FORGOT PASSWORD ACTION DATA ENDS

  return (
    <div className="reset_password_main">
      <PageTitle title="Forgot Password" />

      <form
        onSubmit={handleForgotPassword}
        className="card_reset_pass"
        style={{ backgroundColor: solidColor }}
      >
        <div className="label_container">
          <label htmlFor="panNumber">
            <input
              type="radio"
              name="choose_label"
              id="panNumber"
              value="pan"
              checked={selectedType === "pan" ? true : false}
              onChange={(e) => setSelectedType(e.target.value)}
            />
            PAN Number
          </label>
          <label htmlFor="emailId">
            <input
              type="radio"
              name="choose_label"
              id="emailId"
              value="email"
              checked={selectedType === "email" ? true : false}
              onChange={(e) => setSelectedType(e.target.value)}
            />
            Email Id
          </label>
        </div>
        {selectedType === "pan" ? (
          <CustomInput
            icon=""
            label="Registered PAN Number"
            type="text"
            onChange={(e) => setPanNumber(e.target.value)}
            placeholder="Type your registered PAN Number"
            name="panNumber"
            value={panNumber}
            uppercase={true}
          />
        ) : (
          <CustomInput
            icon=""
            label="Registered Email Id"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Type your registered email Id"
            name="email"
            value={email}
          />
        )}
        {successMessage &&
        (
          <p
            style={{
              color: "white",
              background: "green",
              padding: 10,
              fontSize: 16,
              marginBottom: 15,
              textAlign: "center",
            }}
          >
            {successMessage}
          </p>
        )}

        <button type="submit" className="changePassBtn">
          {loading ? "Loading..." : "Send Request"}
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
