import { axiosInstance } from "../../utils/axiosConfig";
import {
    CLEAR_TEAM_ERROR,
  CLEAR_TEAM_STATE,
  TEAM_FAIL,
  TEAM_REQUEST,
  TEAM_SUCCESS,
} from "../constants/teamConstant";

// GET MY TEAM
export const getMyTeam = (userId, teamLevel) => async (dispatch) => {
  try {
    dispatch({
      type: TEAM_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrClient")
      ? JSON.parse(sessionStorage.getItem("jsrClient"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/my-team/${userId}/${teamLevel}`, config);

    dispatch({
      type: TEAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TEAM_FAIL,
      payload: error.response.data.message,
    });
  }
};


// CLEAR ERROR ACTION FUNCTION
export const clearTeamErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_TEAM_ERROR });
  };
  
  // CLEAR ERROR ACTION FUNCTION
  export const clearTeamState = () => async (dispatch) => {
    dispatch({ type: CLEAR_TEAM_STATE });
  };
  