import { axiosInstance } from "../../utils/axiosConfig";
import {
    AGENT_POLICY_FAIL,
    AGENT_POLICY_REQUEST,
  AGENT_POLICY_SUCCESS,
  CLEAR_POLICY_ERROR,
  CLEAR_POLICY_STATE,
  DIRECT_TEAM_POLICY_FAIL,
  DIRECT_TEAM_POLICY_REQUEST,
  DIRECT_TEAM_POLICY_SUCCESS,
} from "../constants/policyConstant";

// GET AGENT POLICIES
export const getAgentPolicies = (agentId) => async (dispatch) => {
  try {
    dispatch({
      type: AGENT_POLICY_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrClient")
      ? JSON.parse(sessionStorage.getItem("jsrClient"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/agent-policy/${agentId}`, config);

    dispatch({
      type: AGENT_POLICY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AGENT_POLICY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET DIRECT TEAM POLICIES
export const getDirectTeamPolicies = () => async (dispatch) => {
  try {
    dispatch({
      type: DIRECT_TEAM_POLICY_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("jsrClient")
      ? JSON.parse(sessionStorage.getItem("jsrClient"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/direct-team-policy`, config);

    dispatch({
      type: DIRECT_TEAM_POLICY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DIRECT_TEAM_POLICY_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearPolicyErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_POLICY_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearPolicyState = () => async (dispatch) => {
  dispatch({ type: CLEAR_POLICY_STATE });
};
