import React from "react";
import "../styles/footer.scss";
import { Link } from "react-router-dom";
import { CiFacebook, CiInstagram, CiTwitter, CiLinkedin } from "react-icons/ci";

const Footer = () => {
  return (
    <div className="footer_main">
      <div className="container">
        <div className="footer_content">
          <div className="quickLinks">
            <Link>Company</Link>
            <Link>About Us</Link>
            <Link to="/contact-us">Contact Us</Link>
            {/* <Link>Team</Link> */}
            <Link>Product</Link>
            {/* <Link>Blog</Link> */}
            {/* <Link>Pricing</Link> */}
          </div>
          <div className="socialLinks">
            <Link to="#!">
              <CiFacebook />
            </Link>
            <Link to="#!">
              <CiInstagram />
            </Link>
            <Link to="#!">
              <CiTwitter />
            </Link>
            <Link to="#!">
              <CiLinkedin />
            </Link>
          </div>
          <div className="copyrightText">
           JSR Insurance, 221, Mahendergarh, haryana 123654.
          </div>
          <div className="copyrightText">
            Copyright &copy; 2024 JSR Insurance.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
