import { CLEAR_TEAM_ERROR, TEAM_FAIL, TEAM_REQUEST, TEAM_SUCCESS } from "../constants/teamConstant";

  
  // GET MY TEAM REDUCER
  export const getMyTeamReducer = (state = {}, action) => {
    switch (action.type) {
      case TEAM_REQUEST:
        return {
          loading: true,
          data: [],
        };
      case TEAM_SUCCESS:
        return {
          loading: false,
          data: action.payload,
        };
      case TEAM_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_TEAM_ERROR:
        return {
          ...state,
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
  