import React from "react";
import "../styles/contact.scss";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SectionHeading from "../components/SectionHeading";
import { CiLocationOn } from "react-icons/ci";
import { BsEnvelope } from "react-icons/bs";
import { FaRegComments } from "react-icons/fa";

const Contact = () => {
  return (
    <>
      <Header />
      <section className="contactUs_main">
        <div className="container">
          <SectionHeading
            heading="Contact Info"
            underLine={true}
            center={true}
          />

          <div className="top_section">
            <div className="address_section">
              <div className="icon">
                <CiLocationOn />
              </div>
              <h3>Our Address</h3>
              <p>Mahndergarh, HR (INDIA)</p>
            </div>
            <div className="info_section">
              <div className="icon">
                <BsEnvelope />
              </div>
              <h3>Contact Info</h3>
              <p>Open a chat or give us call at</p>
              <div className="link">
                <Link to={`tel:+91${process.env.REACT_APP_PHONE_NO}`}>{process.env.REACT_APP_PHONE_NO}</Link>
              </div>
            </div>
            <div className="chat_section">
              <div className="icon">
                <FaRegComments />
              </div>
              <h3>Live Support</h3>
              <p>live service on mail</p>
              <div className="chat_link">
                <Link to={`mailto:${process.env.REACT_APP_MAIL}`}>{process.env.REACT_APP_MAIL}</Link>
              </div>
            </div>
          </div>

          <section className="message_section">
            <h3>Any question<span>?</span> Send us message<span>.</span></h3>
            <form className="form">
              <input type="text" placeholder="Your Name" />
              <input type="email" name="" id="" placeholder="Email Address" />
              <input type="email" name="" id="" placeholder="Phone Number" />
              <textarea
                name=""
                id=""
                placeholder="Your Message"
                rows="4"
              ></textarea>
              <button className="btn">Send Message</button>
            </form>
          </section>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Contact;
