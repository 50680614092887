import React, { useEffect, useState } from "react";
import "../styles/login.scss";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../components/CustomInput";
import { loginBG } from "../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { clearErrors, login } from "../redux/actions/userAction";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const backGroundStyle = {
    backgroundImage: `url(${loginBG})`,
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };

  const [panNumber, setPanNumber] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    if (!panNumber && !password) {
      toast.warning("Please fill the input values");
    } else {
      dispatch(login("", panNumber, password));
    }
  };

  const { loading, userInfo, error } = useSelector((state) => state.userLogin);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }

    if (userInfo?.success) {
      navigate("/dashboard");
    }
  }, [error, dispatch, navigate, userInfo?.success]);

  return (
    <div className="login_main" style={backGroundStyle}>
      <div className="loginContent">
        <div className="container">
          {/* <div className="back">
            <Link to="/">Back To Home</Link>
          </div> */}
          <form className="login_form">
            <h1>Sign in</h1>
            {/* <p>
              Still don‘t have an account? <Link to="/register">Sign Up</Link>
            </p> */}
            <CustomInput
              icon=""
              label="Email/Unique ID"
              type="text"
              onChange={(e) => setPanNumber(e.target.value)}
              placeholder="Enter your email/unique id"
              name="panNumber"
              value={panNumber}
            />
            <CustomInput
              icon=""
              label="Password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              name="password"
              value={password}
              eyeIcon={showPassword ? <IoIosEye /> : <IoIosEyeOff />}
              setshowPassword={setShowPassword}
            />
            <Link className="forgot_pass_btn" to="/forgot-password">
              Forgot Password?
            </Link>
            <button onClick={handleLogin} type="submit">
              {loading ? "Loading..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
