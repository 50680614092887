import React from "react";
import "../styles/header.scss";
import logo from "../images/jsr-logo.png";
import { Link } from "react-router-dom";
import { useAuth } from "../context/userContext";

const Header = () => {
  const { auth } = useAuth();
  const user = auth?.user;
  return (
    <div className="header_main">
      <div className="container">
        <div className="header_content">
          <div className="logo">
            <Link to="/" title="home">
              <img src={logo} alt={logo} />
            </Link>
          </div>
          <div className="links">
            {user?._id ? (
              <Link to="/dashboard">Dashboard</Link>
            ) : (
              <Link className="userBtn" to="/login">
                Sign in
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
