import React from "react";
import styled from "styled-components";

const Card = styled.div`
  border: 1px solid lightgrey;
  border-radius: 10px;
  font-weight: 500;
  background: #000;
  color: #f2f2f2;
  padding: 15px;
  text-align: center;
  font-size: 20px;
`;

const NoData = ({ content }) => {
  return (
    <Card className="no_data">
      <p>{content ? content : "Data not found"}</p>
    </Card>
  );
};

export default NoData;
