import {
  AGENT_POLICY_FAIL,
  AGENT_POLICY_REQUEST,
  AGENT_POLICY_SUCCESS,
  CLEAR_POLICY_ERROR,
  DIRECT_TEAM_POLICY_FAIL,
  DIRECT_TEAM_POLICY_REQUEST,
  DIRECT_TEAM_POLICY_SUCCESS,
} from "../constants/policyConstant";

// GET AGENT POLICIES REDUCER
export const getAgentPoliciesReducer = (state = {}, action) => {
  switch (action.type) {
    case AGENT_POLICY_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case AGENT_POLICY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case AGENT_POLICY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET DIRECT TEAM POLICIES REDUCER
export const getDirectTeamPoliciesReducer = (state = {}, action) => {
  switch (action.type) {
    case DIRECT_TEAM_POLICY_REQUEST:
      return {
        loading: true,
        data: {},
      };
    case DIRECT_TEAM_POLICY_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case DIRECT_TEAM_POLICY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
