import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, logout, userProfile } from "../redux/actions/userAction";
import { toast } from "react-toastify";
import {
  clearPolicyErrors,
  getAgentPolicies,
} from "../redux/actions/policyAction";
import {
  clearCompanyErrors,
  getAllCompanies,
} from "../redux/actions/companyAction";

const userContext = createContext();

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [auth, setAuth] = useState({
    user: null,
    token: "",
    loading: false,
  });

  // DEFAULT AXIOS
  // axios.defaults.headers.common["Authorization"] = `Bearer ${auth?.token}`;

  const {
    userInfo: profileData,
    isAuthenticated,
    error,
    loading: profileLoading,
  } = useSelector((state) => state.profile); // GET USERDATA FROM PROFILE STATE

  const { userInfo: loginUser } = useSelector((state) => state.userLogin); // GET TOKEN DATA FROM LOGIN STATE

  useEffect(() => {
    const token = loginUser?.token;
    if (token) {
      dispatch(userProfile()); // GET USER PROFILE DATA
    }
  }, [dispatch, loginUser?.token]);

  // SET COMING DATA FROM PROFILE STATE
  useEffect(() => {
    if (error && loginUser?.token) {
      toast.error(error);
      dispatch(clearErrors());
      dispatch(logout());
    }

    if (isAuthenticated) {
      setAuth({
        user: profileData?.user,
        token: loginUser?.token,
        loading: profileLoading,
      });
    } else if (!isAuthenticated) {
      setAuth({
        user: {},
        token: "",
        loading: false,
      });
    }
  }, [
    isAuthenticated,
    loginUser?.token,
    profileData?.user,
    error,
    dispatch,
    profileLoading,
  ]);

  // GET AGENT POLICY START
  const {
    loading: agentPolicyLoading,
    data: agentPolicyData,
    error: agentPolicyError,
  } = useSelector((state) => state.agentPolicies);

  useEffect(() => {
    if (auth?.user?._id) {
      dispatch(getAgentPolicies(auth?.user?._id));
    }
  }, [auth?.user?._id, dispatch]);

  useEffect(() => {
    if (agentPolicyError) {
      toast.error(agentPolicyError);
      dispatch(clearPolicyErrors);
    }
  }, [dispatch, agentPolicyError]);
  // GET AGENT POLICY ENDS

  // GET INSURER COMPANIES START
  const {
    loading: insurerCompanyLoading,
    data: insurerCompanyData,
    error: insurerCompanyError,
  } = useSelector((state) => state.allInsurerCompanies);

  const allCompanies = insurerCompanyData?.companies;

  useEffect(() => {
    if(auth?.user?._id){
      dispatch(getAllCompanies());
    }
  }, [dispatch, auth?.user?._id]);

  useEffect(() => {
    if (insurerCompanyError) {
      toast.error(insurerCompanyError);
      dispatch(clearCompanyErrors());
    }
  }, [dispatch, insurerCompanyError]);

  // GET INSURER COMPANIES ENDS

  return (
    <userContext.Provider
      value={{
        auth,
        setAuth,
        agentPolicyLoading,
        myPolicies: agentPolicyData?.policies,
        insurerCompanyLoading,
        allCompanies,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

const useAuth = () => useContext(userContext);

export { useAuth, UserProvider };
