import React from "react";
import { logo } from "../assets/images";
import styled from "styled-components";
import { useAuth } from "../context/userContext";

const Section = styled.div`
  .wrapper {
  }
  .top_bar {
    background: #42275a;
    border-radius: 0 0 20px 20px;
    display: flex;
    justify-content: center;
    padding: 20px 0 100px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    > img {
      width: 150px;
    }
  }
  .welcomeContent {
    background: #f2f2f2;
    max-width: 500px;
    display: block;
    text-align: center;
    padding: 20px;
    border-radius: 20px;
    color: rgb(52, 71, 103);
    z-index: 2;
    position: absolute;
    top: 190px;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    box-sizing: border-box;
    display: block;
    margin: 0 auto;

    > h1 {
      font-size: 25px;
      margin-bottom: 15px;
    }

    > p {
      font-size: 20px;
      margin-bottom: 15px;
    }

    > button {
      background: #42275a;
      color: #f2f2f2;
      padding: 10px;
      width: 100%;
      border: none;
      border-radius: 100px;
      cursor: pointer;
      font-size: 18px;
    }
  }
`;

const WelcomeMessage = () => {
  const { auth } = useAuth();
  const user = auth?.user;

  const handleFirstVisit = () => {
    localStorage.setItem("jsrFirstVisit", true);
  };

  return (
    <Section className="welcomeMessage_main">
      <div className="wrapper">
        <div className="top_bar">
          <img src={logo} alt="logo" />
        </div>
        <div className="welcomeContent">
          <h1>Hi {user?.fullName}!</h1>
          <p>
            Now you can start selling policies from here and can earn great
            points.
          </p>
          <button onClick={handleFirstVisit}>Go Ahead!</button>
        </div>
      </div>
    </Section>
  );
};

export default WelcomeMessage;
