import React from "react";
import "./App.css";
import Home from "./pages/Home";
import { Outlet, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import MyAccount from "./pages/MyAccount";
import Policies from "./pages/Policies";
import Contact from "./pages/Contact";
import Help from "./pages/Help";
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RedirectSpinner from "./components/RedirectSpinner";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import "./styles/tableDataStyle.scss";
import MyTeam from "./pages/MyTeam";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword.js";
import PageNotFound from "./pages/PageNotFound.js";
import TeamPolicies from "./pages/TeamPolicies.js";
import WelcomeMessage from "./components/WelcomeMessage.js";

const App = () => {
  const { loading, userInfo } = useSelector((state) => state.profile);

  const user = userInfo?.user;
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ScrollToTop />
          <ToastContainer />
          <Routes>
            {/* <Route path="/home" element={<Home />} /> */}
            <Route exact path="/" element={<Login />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/" element={user?._id ? <Outlet /> : <RedirectSpinner />}>
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/my-team/:userId" element={<MyTeam />} />
              <Route path="/policies" element={<Policies />} />
              <Route path="/team-policies" element={<TeamPolicies />} />
              <Route path="/help" element={<Help />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
