import styled from "styled-components";

export const CircleCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  > .mainData {
    font-size: 35px;
    color: #42275a;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > .title {
    font-size: 25px;
    color: rgb(52, 71, 103);
    font-weight: 500;
  }
  > .desc {
    font-size: 15px;
    color: grey;
    overflow: hidden;
    white-space: nowrap;
  }
  > .button {
    font-size: 16px;
    color: #fff;
    padding: 6px 10px;
    border-radius: 8px;
    border: 1px solid transparent;
    background: rgb(52, 71, 103);
    margin-top: 8px;
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      color: rgb(52, 71, 103);
      background: #fff;
      border: 1px solid rgb(52, 71, 103);
    }
  }
`;
