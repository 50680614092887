export const AGENT_POLICY_REQUEST = "AGENT_POLICY_REQUEST";
export const AGENT_POLICY_SUCCESS = "AGENT_POLICY_SUCCESS";
export const AGENT_POLICY_FAIL = "AGENT_POLICY_FAIL";

export const DIRECT_TEAM_POLICY_REQUEST = "DIRECT_TEAM_POLICY_REQUEST";
export const DIRECT_TEAM_POLICY_SUCCESS = "DIRECT_TEAM_POLICY_SUCCESS";
export const DIRECT_TEAM_POLICY_FAIL = "DIRECT_TEAM_POLICY_FAIL";

export const CLEAR_POLICY_ERROR = "CLEAR_POLICY_ERROR";
export const CLEAR_POLICY_STATE = "CLEAR_POLICY_STATE";
