import React from "react";
import "../../styles/dashboardLayout.scss";
import DashboardBottomBar from "../DashboardBottomBar";

const DashboardLayout = ({ children }) => {
  return (
    <div className="dashboardLayout_main">
      <div className="childrenMain">{children}</div>
      <DashboardBottomBar />
    </div>
  );
};

export default DashboardLayout;
