import React from "react";
import "../styles/teamTree.scss";
import { FaArrowRight } from "react-icons/fa";

const TeamTree = ({teamCount, name, onClick, panNumber}) => {
  return (
    <div className="teamTree_main" onClick={onClick}>
      <div className="name_size">
        <div className={`team_count ${teamCount > 0 ? "bg-green" : "bg-red"}`}>{teamCount}</div>
        <div className="associate_name">{name} <span>({panNumber})</span></div>
      </div>
      <div className="arrowHandle">
        <FaArrowRight />
      </div>
    </div>
  );
};

export default TeamTree;
