import React, { useEffect, useState } from "react";
import "../styles/resetPassword.scss";
import PageTitle from "../components/PageTitle";
import CustomInput from "../components/CustomInput";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { toast } from "react-toastify";
import {
  clearErrors,
  clearState,
  resetUserPassword,
} from "../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();

  // GET RANDOM SOLID COLOR
  function getRandomSolidColors() {
    const r = Math.floor(50 + Math.random() * 105); // Between 1 and 1
    const g = Math.floor(50 + Math.random() * 105); // Between 1 and 1
    const b = Math.floor(50 + Math.random() * 105); // Between 1 and 1
    const colorArr = `rgb(${r}, ${g}, ${b})`;

    return colorArr;
  }
  const solidColor = getRandomSolidColors();

  // RESET PASSWORD HANDLE START
  const { loading, data, error } = useSelector((state) => state.resetPassword);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      toast.error("Plaese fill all required fields");
    }
    if (password !== confirmPassword) {
      toast.error("Password and confirm password must be same");
    } else if (password.length <= 7) {
      toast.error("Password length must be minimum of 8 characters");
    } else {
      dispatch(resetUserPassword(token, password));
    }
  };

  useEffect(() => {
    if (data?.success) {
      toast.success(data?.message);
      dispatch(clearState());
      navigate("/");
    }
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
  }, [data?.success, data?.message, dispatch, navigate, error]);

  // RESET PASSWORD HANDLE ENDS

  return (
    <div className="reset_password_main">
      <PageTitle title="Reset Password" />

      <form
        onSubmit={handleResetPassword}
        className="card_reset_pass"
        style={{ backgroundColor: solidColor }}
      >
        <CustomInput
          icon=""
          label="Strong Password"
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Set a strong password which you remember"
          name="password"
          value={password}
          eyeIcon={showPassword ? <IoIosEye /> : <IoIosEyeOff />}
          setshowPassword={setShowPassword}
        />
        <CustomInput
          icon=""
          label="Confirm Password"
          type={showPassword ? "text" : "password"}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Set a strong password which you remember"
          name="confirmPassword"
          value={confirmPassword}
          eyeIcon={showPassword ? <IoIosEye /> : <IoIosEyeOff />}
          setshowPassword={setShowPassword}
        />
        <button type="submit" className="changePassBtn">
          {loading ? "Loading..." : "Reset Password"}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
