import React from "react";
import styled from "styled-components";

const Title = styled.div`
  background: #42275a;
  color: #f2f2f2;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 1px 1px 7px 1px #000;
  > h1 {
    font-size: 30px;
    text-transform: capitalize;
    @media screen and (max-width: 767px) {
      font-size: 25px;
    }
  }
`;

const PageTitle = ({ title }) => {
  return (
    <Title>
      <h1>{title}</h1>
    </Title>
  );
};

export default PageTitle;
